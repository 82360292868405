export const desc = {

  Header: [
    'خبرة في التدريب أكثر من سبع سنين فما تقلقش هنوصلك لهدفك في أقل وقت ممكن',
    'هنحققلك هدفك بما يتناسب مع نظام حياتك وبالسعر المناسب ليك',
    'كل شيء ممكن بالذات لو معاك مدرب ما بيبخلش عليك بوقته',
    'أخ بيحبك وبيخاف عليك ومش هيرضالك غير إنك تحقق هدفك',
  ],

  Service: [
    "احصل على خطط مخصصة لتحقيق أهدافك الرياضية.",
    "خطط غذائية مميزة تتناسب مع طبيعة جسمك و نظام حياتك.",
    "نظام كامل ومدروس لاستعادة قوتك بعد الإصابات.",
    "طور قوتك وتحملك مع برامج شاملة للياقة البدنية.",
  ],

  About: [
    "بسم الله نبدا تعالوا لما اخدكم معايا ف رحلتي المتواضعة ... يلا بينا ...بدايتي كاي واحد فينا كان رفيع جدا وكانت الهدوم مبتليقش عليه والمظهر مش حلو خالص خصوصا لو كنت طويل بالذات فأنت هتبان رفيع جامد وهنا جالي قرار أن لازم اشترك في جيم واغير من نفسي كان قرار صعب لكن انتا ممكن تشوفه سهل عادي لكن تعالي اقولك ايه اللي انا واجهته .بص يبطل وأنتا ف ماشي ف طريقك او اي قرار عمتا خدته اعرف انك هتواجهه عقبات كتيره ..المشاكل اللي واجهتني لما قررت ادخل الجيم طبعا اول حاجه هي تريقه الناس عليه وديه لوحدها تقعدك ف البيت",
    "طول عمرك بس العكس انا خدتها اكبر حافز ليا ودائما هقولك خلي الحافز بتاعك دائما سلبي واهتم اكتر من الحافز الإيجابي عشان مهما كان الحافز السلبي بيضايق مياثرش عليك وأنتا ماشي ف طريقك ..أومن دائما بمقوله...غياب الدعم لازم يكون هو اكبر حافز ليك ... يوم وري يوم شهر وري شهر والدنيا بدأت تتغير معايا وجسمي بدأ يتطور الحمد لله وبفضل ربنا عليه بدأت أفكاري واهدافي تتطور وبدل مانا كنت داخل عشان ازود وزني الموضوع زاد معايا وقررت ادخل",
    "واشارك ف بطولات والحمد لله شاركت ف اول بطوله بحيره وحصلت علي مركز تالت وسط اكتر من 17لاعب كانت أهم بطوله ف حياتي كانت اول خطوه ليا ف عالم البطولات وبعد كده بطوله وري بطوله والحمد شاركت ف اسكندريه 13مره من ضمنهم 9مراكز اول بفضل الله وبعد كده حصلت علي 6بطولات جمهوريه ودخلت تجارب ابطال عالم وطبعا ده غير البطولات الخاصه اللي حصلت فيها علي مراكز متقدمه واخر بطوله احمد علي الدوليه ومنها بطوله ايرون مان وبطوله حورس الدوليه وهكذا لغايه موصلوا ل56بطوله علي مدار 9سنيين",
    "ونص بدأتها من تحت خالص ومكمل باذن الله لغايه ماحقق حلمي وطبعا اشتغلت ف مجال التدريب بقالي اكتر من 5سنيين خبره ف المجال ودراسه مكثفه عن كل مايخص اللعبه سواء تغذيه تدريب والحمد لله عملت تحولات كتير من تحت ايدي بفضل ربنا وبإذن الله مكملين نصيحتي ليك الطريق مش سهل .اوعي تقف .اوعي تستلم .اوعي تيأس لمجرد أن ف حاجه جات وعطلتك عن تحقيق هدفك الحياه مش عادله وعمرها مهتكون عادله لأنها دنيا ف الاول والاخير لازم تسعي لازم تتحرك من مكانك مفيش حاجه بالساهل انتا تقدر...انتا بطل",
  ],

  projects: {
    main: "الرجالة دي تعبت معاهم بجد وبشوف في كل واحد فيهم نفسي, فبهتم بكل واحد وعمري ما استخسرت خبرة أو معلومة",
    steps: ["اهتمام", "تقدير", "أبطال"],
  },

  Achieve: [
    "على مدار ستة سنوات شاركت في أكثر من 60 بطولة ",
    "في أكثر من 40 بطولة و بطولة أحمد علي مركز رابع",
    "شارك معايا أكثر من 100 لاعب في أكتر من بطولة في مصر",
  ],

  Family: "اخواتي مش مجرد لعيبه وكل مستوي بنوصلوله فخر كبير ليه",
};
